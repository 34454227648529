import { Row } from "antd"
import React, { Ref, RefObject } from "react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Button from "components/Button/Button/Index"
import { Buttons } from "components/Card/Card"
import { GenericModal } from "components/Modal/Modal"
import { Call } from "types/payload"
import { ModalProps } from "types/props"
import { ClientStore, DocumentsStore } from "types/store"

import { actions } from "./actions"
import { useLanguage } from "locales"

export const hasDocuments = (call: Call): boolean => {
  if (
    !call.is_prescription &&
    !call.is_report &&
    !call.is_workleave &&
    !call.is_certificate
  ) {
    return false
  }
  return true
}

export const ReadableDocument = React.forwardRef(
  (props: { documentUrl?: string }, ref: Ref<HTMLIFrameElement>): JSX.Element =>
    props.documentUrl ? (
      <iframe
        title="readable-document"
        className="iframe-document"
        id="iFrame"
        name="iFrame"
        src={`${props.documentUrl}#toolbar=0`}
        ref={ref}
      />
    ) : (
      <div className="iframe-document"></div>
    )
)

const handlePrintDocument = (
  iframeRef: RefObject<HTMLIFrameElement>,
  setLoading: (boolean: boolean) => void
) => {
  setLoading(false)
  const ifrm = iframeRef?.current as HTMLIFrameElement
  const win: Window | null = ifrm?.contentWindow || null
  const doc: Document | null = ifrm?.contentDocument || null
  // if the pdf takes up space in the body, we print
  // By default , body empty has a length equal to 13 in my case
  if (
    win &&
    typeof win.onload !== "undefined" &&
    (doc?.body as HTMLElement).outerHTML.length > 20
  ) {
    win.print()
    setTimeout(() => {
      setLoading(true)
    }, 1000)
  } else {
    setTimeout(() => {
      handlePrintDocument(iframeRef, setLoading)
    }, 1000)
  }
}

export const ModalDocument = ({
  call,
  ...rest
}: Partial<ModalProps> & { call?: Call }): JSX.Element => {
  const { languages } = useLanguage()
  const dispatch = useDispatch()
  const { documentUrl, callId, ...props } = useSelector(
    ({
      client,
      documents,
    }: {
      client: ClientStore
      documents: DocumentsStore
    }) => ({
      documentUrl: call && client.documentUrl,
      callId: client.call?.id || -1,
      ...documents,
    })
  )
  const [printed, setPrinted] = useState<boolean>(false)
  const [printedModal, setPrintedModal] = useState<boolean>(false)

  const [visible, setVisible] = useState<boolean>(!!call)
  const [loading, setLoading] = useState<boolean>(false)
  const [showConfirmSendingDocuments, setShowConfirmSendingDocuments] =
    useState<boolean>(false)

  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    setVisible(!!call)
    if (call) {
      handlePrintDocument(iframeRef, setLoading)
      dispatch(actions.request(call.id))
    } else {
      setLoading(true)
    }
  }, [call])

  const handleSendDocumentsToPharmacy = () => {
    dispatch(actions.sendDocumentsRequest({ id: callId }))
  }
  const ConfirmSendingDocuments = (props: any) => (
    <div>
      <Row justify="center">
        <h2 className="primary-color">
          <b>{languages.confirmTheSendingOfYourDocuments}</b>
        </h2>
        <Buttons style={{ paddingBottom: "100px" }}>
          <Button
            wide="long"
            loading={props.loading}
            disabled={props.loading}
            onClick={() => {
              handleSendDocumentsToPharmacy()
              setShowConfirmSendingDocuments(false)
            }}
          >
            {languages.yes}
          </Button>
          <Button
            wide="long"
            onClick={() => setShowConfirmSendingDocuments(false)}
          >
            {languages.no}
          </Button>
        </Buttons>
      </Row>
      <p
        className="primary-color"
        style={{ position: "absolute", bottom: "-20px" }}
      >
        *{languages.referrerIsAHealthProfessional}
      </p>
    </div>
  )

  const Actions = (props: any) => (
    <>
      <Buttons>
        <Button
          loading={props.loading}
          type="primary"
          disabled={props.loading}
          onClick={() => {
            if (printed) {
              setPrintedModal(true)
            } else {
              setPrinted(true)
              handlePrintDocument(iframeRef, setLoading)
            }
          }}
        >
          {printed ? languages.rePrint : languages.print}
        </Button>
        <Button
          loading={props.loading}
          disabled={props.loading || !props.canSendEmail}
          onClick={() => setShowConfirmSendingDocuments(true)}
        >
          {props.canSendEmail
            ? languages.sendDocumentsToTheReferrer
            : languages.sent}
        </Button>
        <Button
          danger
          type="primary"
          loading={props.loading}
          disabled={props.loading}
          onClick={rest.onAccept}
        >
          {languages.finishAndDisconnect}
        </Button>
      </Buttons>
      <GenericModal
        visible={printedModal}
        title={languages.rePrintTitle}
        acceptText={languages.no}
        acceptButtonProps={{ danger: false, type: "default" }}
        onAccept={() => setPrintedModal(false)}
        cancelText={languages.yes}
        cancelButtonProps={{ danger: true, type: "primary" }}
        onCancel={() => {
          setPrintedModal(false)
          handlePrintDocument(iframeRef, setLoading)
        }}
      >
        {languages.rePrintContent}
      </GenericModal>
    </>
  )

  return (
    <GenericModal
      {...rest}
      title={languages.yourDocuments}
      loading={loading}
      footer={
        rest.footer ||
        (!showConfirmSendingDocuments ? (
          <Actions {...props} />
        ) : (
          <ConfirmSendingDocuments {...props} />
        ))
      }
      visible={visible}
    >
      {!showConfirmSendingDocuments ? (
        <ReadableDocument documentUrl={documentUrl} ref={iframeRef} />
      ) : (
        <></>
      )}
    </GenericModal>
  )
}
