import { cdn } from "core/cdn"

const fr = {
    understood: "Compris",
    birthLocation_with_insee_code: "Lieu / INSEE de naissance",
    birth_lastname: "Nom de naissance",
    birth_location: "Lieu de naissance",
    birth_firstname: "Prénom de naissance",
    pseudo_firstname: "Prénom utilisé",
    pseudo_lastname: "Nom utilisé",
    verified_identity: "Identité vérifiée",
    add_pseudo_firstname: "AJOUTER UN PRENOM UTILISÉ",
    add_pseudo_lastname: "AJOUTER UN NOM UTILISÉ",
    identity_data: "Données d’identité",
    birth_names: "Prénom/Nom de naissance",
    pseudo_names: "Prénom/Nom utilisé",
    some_information_comes_from: "Certaines informations de votre profil ont été récupérées via votre",
    carte_vitale: "carte vitale",
    and_are_not: "et ne sont",
    modifiable: "pas modifiables.",
    sex: "Sexe :",
    you_can_add: "Vous pouvez ajouter ",
    a_pseudo_last_name_different: "un nom utilisé au quotidien, différent",
    a_pseudo_first_name_different: "un Prénom utilisé au quotidien, différent",
    of_your_birth_last_name: "de votre nom de naissance.",
    of_your_birth_first_name: "de votre Prénom de naissance.",
    not_informed: "Non renseignés",
    not_informed_singular: "Non renseigné",
    date_of_birth: "Date de naissance",
    code_insee: "Code INSEE de naissance",
    information_from_carte_vitale: "Voici les informations récupérées de votre carte vitale",
    look_and_verify_relatives_info: "Vérifiez et complétez l'identité de votre proche",
    ICanWait: "Non, je patiente",
    IWantToLeave: "Oui",
    doctorWillBeHereSoon: "Le médecin ne devrait plus tarder...",
    woudlYouRatherCancel: "Êtes-vous sûr(e) d'annuler la demande de téléconsultation et quitter la file d'attente ?",
    doctorIsComingInLessThanFiveMinutes: "Le médecin arrive dans moins de 5 min.",
    lessThanFiveMinutes: "moins de 5 min.",
    youCanJoinCallsRomm: "Vous pouvez dès à présent entrer en salle virtuelle et attendre le médecin quelques minutes.",
    joinVirtualRoom: "Entrer en salle virtuelle",
    accessibility_declaration: "Déclaration d'accessibilité",
    twoFa_choseMethodForResend:
      "Choisissez une méthode pour recevoir à nouveau le code de vérification.",
    twoFa_enterRecievedCode: "Entrez le code de vérification reçu",
    bySMSAt: "par SMS au",
    byPhoneAt: "par téléphone au",
    byEmail: "par Email",
    twoFa_enterValideCode: "Veuillez entrer un code valide.",
    twoFa_resendCode: "RENVOYER LE CODE",
    twoFa_codeHasBeenResentBy: (transport) => `Le code a été renvoyé par ${transport}`,
    sms: "SMS",
    call:"Appel",
    voice: "appel téléphonique",
    twoFa_Titre: "Code de vérification",
    twoFa_invalidCode: "Le code de vérification est incorrect",
    twoFa_activationError:
      "Une erreur est survenue lors de l'activation du 2FA sur votre compte.",
    twoFa_ResendError:
      "Une erreur est survenue lors du renvoi du code de vérification.",
    twoFa_activateTitle:
      "Protégez votre compte en activant la double authentification (2FA)",
    twoFa_activateSubtitle:
      "Pour vous connecter à votre compte, en plus de votre mot de passe, vous recevrez un code à 6 chiffres.",
    diagnosis_authorization_on_going: "Autorisation en cours",
    retry: "Réessayer",
    iTurnedDevicesOff: "J'ai bien éteint les outils",
    diagnosis_information_unknown_error:
      "Le micro et/ou la caméra ne semblent pas fonctionner. Merci de vous rapprocher du référent de la borne.",
    diagnosis_title_unknown_error: "Problèmes techniques détectés",
    diagnosis_contact_support:
      "Pharmacien, contactez le support en cas de besoin :",
    diagnosis_information_permission_denied:
      "Le micro et/ou la caméra ne semblent pas être autorisés par le navigateur. Merci de vous rapprocher du référent de la borne.",
    diagnosis_title_permission_denied:
      "La caméra et/ou le micro ne sont pas autorisés",
    diagnosis_information_waiting_device:
      "Vous devez autoriser le micro et la caméra.",
    diagnosis_title_waiting_device: "Demande d'autorisation en cours...",
    diagnosis_title_success: "Récupération des flux vidéos et audios",
    createYourAccountBeforeAdddingChildRelative:
      "Si vous venez consulter pour votre enfant, veuillez créer votre propre compte dans un premier temps.",
    createChildRelativeAfter:
      "Une fois votre compte créé, vous pourrez ajouter votre enfant en tant que proche.",
    addChildRelativeWithYourOwnVitalCard:
      "Pour un enfant, le numéro de carte vitale à indiquer doit être celui du parent auquel il est rattaché",
    fix: "Corriger",
    keepThisNumber: "Conserver ce numéro",
    generalist: "Médecin généraliste",
    modifyInformations: "Modifier les informations",
    uploadDocumentQuestion:
      "Souhaitez-vous transmettre des documents médicaux au médecin ?",
    uploadDocumentTypesHints: "(analyses médicales, ordonnance, plaie, ...)",
    uploadDocumentAdd: "Importez puis sélectionnez les documents",
    uploadDocumentSelectOrAdd:
      "Sélectionnez les documents à envoyer ou ajoutez un nouveau document",
    uploadDocumentNoDocumentAvailable: "Aucun document",
    uploadDocumentAddButton: "Nouveau document",
    uploadDocumentGetReady:
      "Préparez-vous à positionner votre document à l’intérieur de la zone verte face à la caméra",
    uDocScanQrCode:
      "Scannez le QR Code à l’aide de ",
    yourPhonesCamera: "la caméra de votre téléphone,",
    thenFollowTheSteop: " puis suivez les étapes indiquées",
    uDImportFRom: "Partagez des documents/photos",
    UDAskSms: "Demandez un lien par SMS, puis suivez les étapes indiquées",
    UDGetLinkBySms: "Recevoir le lien par SMS",
    UDUseBorn: "Utiliser plutôt la caméra de la borne",
    uDImportFromKiosk: "Ou avec la caméra de la borne",
    uDImportFromMobile: "Avec mon téléphone",
    UDSendMeSMS: "Ou recevoir le lien par SMS",
    UDCannotScan: "Je ne peux pas scanner le QR code",
    UDModalText:
      "La photo sera de bien meilleure qualité avec votre téléphone, que souhaitez-vous faire ?",
    UDModalAcceptQRcode: "Utiliser la caméra de mon téléphone",
    UDModalIgnoreQRcode: "Continuer avec la caméra de la borne",
    uploadDocumentGetReadyButton: "Continuer",
    uploadDocumentIamReadyButton: "Je suis prêt",
    uploadDocumentCancelButton: "Annuler",
    uploadDocumentPreview: "Veuillez sélectionner l'image de meilleure qualité",
    uploadDocumentPreviewTakeAgain: "Reprendre",
    uploadDocumentPreviewValidate: "Valider la photo",
    uploadDocumentAddAnotherPage: "Souhaitez-vous ajouter une page?",
    uploadDocumentChooseType: "Choisissez le type du document",
    uploadDocumentTypeCrm: "Compte-rendu médical",
    uploadDocumentTypeOrdo: "Ordonnance",
    uploadDocumentTypeMT: "Médicaments / traitement",
    uploadDocumentTypeC: "Certificat",
    uploadDocumentTypeAG: "Analyses biologiques",
    uploadDocumentTypeCmd: "Courrier médical d'adressage",
    uploadDocumentChooseTypeButton: "Valider",
    uploadDocumentDocName: "Entrez le nom de votre document",
    uploadDocumentloading: "Chargement du document en cours.",
    uploadDocumentWait: "Veuillez patienter quelques instants...",
    uploadDocumentSuccess: "Le document a bien été importé dans votre profil !",
    uploadDocumentSuccessButton: "Continuer",
    uploadDocumentSendButton: "Transmettre les documents",
    UploadRecommendedText:
      "Afin de renouveler votre ordonnance, vous devriez transmettre une photo de votre document.",
    photoTransferText:`Afin d'analyser les résultats de biologie, merci de transmettre une photo des documents`,
    UploadPreviewDC:
      "Appuyer sur les flèches ou double-cliquer sur l'image frontale pour la visualiser en plus grand.",
    UDModalTransmit: "TRANSMETTRE",
    UDModalIgnore: "IGNORER",
    Renewal: "Renouvellement d'ordonnance",
    genericAPIError: "Une erreur est survenue.",
    relativeConsent:
      "L'ajout d'un proche sur MEDADOM necéssite son consentement",
    noCreditCardAdded: "Vous n'avez aucune carte d'enregistrée",
    noTLC: "vous n'avez pas de téléconsultation",
    yourCreditCards: "Vos cartes",
    subtitleCallAcceptedAndSoon:
      "est en consultation, vous êtes le prochain patient.",
    waitingDoctor: "Merci de patienter...",
    tagline: "Votre partenaire santé",
    chooseALanguage: "Choix de la langue",
    relaunchReader:"Veuillez relancer le lecteur ou passer à la suite",
    andYour: "Et votre",
    and: "Et",
    stayLogged: "Rester connecté",
    owner: "Titulaire",
    OtherRelative: "Autre proche",
    genericErrorPaymentCard:
      "Une erreur est survenue lors de l'ajout de votre moyen de paiement",
    setupIntentErrorStripe: "Impossible d'ajouter la carte.",
    genericErrorStripe:
      "Une erreur est survenue. Impossible de lancer la requête.",
    chooseThisRelative: "Choisir ce proche",

    beSureToBeDisconnected: "Êtes-vous sûr(e) de vouloir vous déconnecter ?",
    finishAndDisconnect: "Terminer et déconnexion",
    myAdministratorAccount: "Mon compte administrateur",
    launchFullVideoTuto: "Lancer la formation complète",
    launchChapterVideoTuto: "Parcourir les vidéos de formation",
    videoManual: "Vidéos d'utilisation de la borne",
    close: "Fermer",
    StartWithTheTerminal: "Démarrer sur la borne",
    WorkWithMedadom: "Travailler avec MEDADOM",
    TerminalOperation: "Fonctionnement du terminal",
    ClientParcours: "Parcours patient",
    ToolsUseCase: "Utilisation des outils",
    TheToolsUseCase: "L'utilisation des outils",
    UseCases: "Cas d’usage",
    TheUseCases: "Les cas d'usage",
    ContraIndications: "Contre-indications",
    TheContraIndications: "Les contre-indications",
    Inscription: "Inscription",
    ClientInscription: "Inscription du patient",
    HygieneProcedures: "Procédures d'hygiène",
    TheHygieneProcedure: "La procédure d'hygiène",
    Duration: "Durée",
    confirmTheSendingOfYourDocuments:
      "Confirmez-vous l'envoi de vos documents au pharmacien* ?",
    myStatistics: "Mes statistiques",
    myTutorialVideos: "Vidéos de formation",
    modal_pharmaAccount_title: "Espace professionnel",
    modal_pharmaAccount_information:
      "En cas de question, vous pouvez contacter le support: <br /> <span>Du <b>lundi au samedi </b>de <b>9h à 19h30</b></span> <br /> <span>au <b>01 88 24 22 87</b></span> <br/><span>à l'adresse <b>contact@medadom.com</b></span>",
    select_reason: "Selectionnez un motif ...",
    select_birth_location: "lieu de naissance",
    anamneseReasonQuestion: "Quel est le motif de votre consultation ?",
    anamneseReasonQuestion_relative_male:
      "Quel est le motif de consultation de votre proche ?",
    anamneseReasonQuestion_relative_female:
      "Quel est le motif de consultation de votre proche ?",
    birthCity: "lieu de naissance",
    birthCountry: "pays de naissance",
    birthLocation: "Lieu de naissance",
    createCardSuccess: "Votre carte a été ajoutée",
    createCardError: "Votre carte n'a pas pu être ajouté",
    city: "Ville",
    covidHTML: `Coronavirus\r\n (Covid 19)`,
    covid: "Coronavirus (Covid 19)",
    allergy: "Allergies",
    burn: "Brûlure",
    contraception: "Contraception",
    cystitis: "Cystite",
    jointpain: "Douleurs articulaires (genou, poignet)",
    jointpainWithoutParenthesis: "Douleurs articulaires",
    diarrhea_or_vomiting: "Diarrhée ou vomissement",
    toothpain: "Douleurs dentaires",
    back_pain: "Douleurs lombaires",
    rash: "Éruption cutanée",
    tired: "Fatigue / anxiété / stress",
    fever: "Fièvre",
    sexual_infection: "Infection sexuelle",
    sore_throat: "Maux de gorge",
    headache: "Maux de tête",
    stomach_ache: "Maux de ventre",
    eye_pain: "Œil rouge et/ou douleur",
    cancel_warning:
      "En cas d'annulation, vous perdrez votre place dans la file d'attente. \r\n Souhaitez-vous annuler votre demande ?",
    sting: "Piqûre / morsure / blessure",
    otitis: "Oreilles/otite",
    prescription_refill: "Renouvellement d'ordonnance",
    period_pain: "Règles douloureuses",
    lab_results: "Résultats de laboratoire",
    cold_and_flu: "Rhume et syndrome grippal",
    sinus: "Sinus ou nez bouché",
    cough: "Toux",
    otherReason: "Autre",
    DMLA: "DMLA",
    DoYouHaveANir: "Avez-vous une carte vitale ?",
    IHaveAccount: "J'ai déjà <b style='font-weight:normal'>un compte</b>",
    IHaveNoAccount: "Je n'ai pas <b style='font-weight:normal'>de compte</b>",
    IHaveNoEmail: "J'atteste ne pas avoir d'adresse e-mail",
    IHaveNoNir: "Je n'ai pas de carte vitale",
    IHaveNir: "J'ai une carte vitale",
    RelativeHasNir: "Le proche a une carte vitale",
    TooManyRequest: "Trop de requêtes. Veuillez réessayer ultérieurement",
    WhenWasThelastConsultation:
      "A quand remonte votre dernière consultation (physique) chez un orthoptiste/ophtalmologiste ?",
    aPatientLogged: "Un patient est en cours d'utilisation : ",
    ame: "Aide Médicale d'État",
    c2s: "Couverture Maladie Universelle",
    ald: "Affection Longue Durée",
    indicateYourRights: "Merci d'indiquer vos droits :",
    indicateRelativeRights: "Merci d'indiquer les droits de votre proche :",
    rightsAttest:
      "j'atteste sur l'honneur que je bénéficie de la C2S (CMU ou AME) ou de l'ALD. Dans le cas contraire, je m'expose à des poursuites.",
    rightsAttestRelative:
      "j'atteste sur l'honneur que mon proche bénéficie de la C2S (CMU ou AME) ou de l'ALD. Dans le cas contraire, je m'expose à des poursuites.",
    ameModalTitle: "Attestation sur l'honneur",
    fraudIsPunished: "Toute fraude à l'Assurance Maladie est punie",
    ameModalAccept: "J'atteste sur l'honneur la validité",
    ameModalCancel: "Je ne bénéficie pas",
    ameModalCancelRelative: "Mon proche ne bénéficie pas",
    estimatedTime: "Attente estimée",
    yourPaymentMethod: "Votre moyen de paiement",
    youWillPayOnlyAfterConsultation:
      "Vous ne serez facturé qu'à la fin de la téléconsultation",
    ameRelativeBenefit: "Le proche bénéficie de la CMU, AME, ALD",
    WeAutomaticallyDetectedBenefit:
      "La carte vitale nous indique votre appartenance CMU, AME, ALD, 100%",
    ameIBenefit: "Je bénéficie de la CMU, AME, ALD",
    ameModalContent: `<table class="table-modal">
        <tr> 
          <td> 
            <img src="${cdn("icons/carte_vitale_picto.svg")}"> 
          </td>
          <td>
            <span class="modal-content">
            <b>Si vous êtes bénéficiaire de la C2S (CMU ou AME) ou ALD, vous bénéficiez d'une exonération de frais.</b>
            Pour cela, vous devez impérativement avoir une attestation à jour de votre Couverture Maladie Universelle, de votre Aide Médicale d'État, ou de votre Affection Longue Durée.
          </span>
            </td>
        </tr>
        <tr>
          <td> 
            <img src="${cdn("icons/pharmacien_picto.svg")}"> 
          </td>
          <td>
            <span class="modal-content">
            Celle-ci pourra être vérifiée par le pharmacien.
            </span>
          <td>
        </tr>
        <tr>
          <td> 
            <img src="${cdn("icons/attestation_honneur_picto.svg")}"> 
          </td>
          <td>
            <span class="modal-content">
            <b>
            J'atteste sur l'honneur que je respecte les conditions énoncées ci-dessus pour une exonération des frais,
            et dans le cas contraire je m'expose à des poursuites.
            </b>
            </span>
          </td>
        </tr>
        <table>`,
    cardRefused: "Paiement refusé",
    errorPaymentModalTitle:
      "Votre carte de paiement a été refusée par notre prestataire de paiement. Merci de vous assurer que :",
    errorPaymentModalChangeCard: "Changer de carte",
    errorPaymentModalRetry: "Réessayer",
    errorPaymentModal_html: `
    <table class="table-modal small">
        <tr>
          <td> 
            <img src="${cdn("icons/picto_paiement.svg")}"> 
          </td>
          <td>
            <span class="modal-content">
              Vous avez des <b>fonds suffisants sur votre compte bancaire</b>
            </span>
          <td>
        </tr>
        <tr>
        <td> 
          <img src="${cdn("icons/picto_internet.svg")}"> 
        </td>
        <td>
          <span class="modal-content">
            Vous avez <b>activé le paiement sur internet</b>
          </span>
        <td>
      </tr>
      <tr>
        <td> 
          <img src="${cdn("icons/picto_3ds.svg")}"> 
        </td>
        <td>
          <span class="modal-content">
          Vous avez <b>activé le 3DSecure</b> le cas échéant (double authentification des paiements en ligne)
          </span>
        <td>
      </tr>
      <tr>
        <td> 
          <img src="${cdn("icons/picto_carte_valide.svg")}"> 
        </td>
        <td>
          <span class="modal-content">
          <b>Votre carte est valide</b> pour le paiement
          </span>
        <td>
      </tr>
    <table>
    <div class="center" style="margin-top:20px"> 
      <b class="error">En cas de besoin, merci de contacter votre banque pour confirmation</b>
    </div>
    `,
    accountCreated: "Votre compte a bien été créé",
    accountError: "Votre compte n'a pas pu être créé",
    addARelative: "Ajouter un proche",
    addCreditCard: "Ajouter la carte",
    addPaimentMethod: "Ajouter une carte de crédit",
    backToPaymentMethod: "Revenir aux moyens de paiement",
    addPhoneNumber: "L'ajouter",
    addRelative: "Ajouter le proche",
    adminInformationWelcome:
      "L’équipe Medadom vous a transmis par mail, lors de votre inscription, votre identifiant ainsi que votre mot de passe. Merci de vous connecter ici avec ces identifiants. Contactez nous au 01 86 65 55 15 en cas de problème.",
    agreement: "Consentement",
    consentOfAssistance: "Consentement de l'accompagnant",
    agreementInvalid: "Vous devez accepter les CGUs",
    alreadyAcount: "J'ai déjà un compte patient Medadom",
    anamneseAllergiesQuestionSubtitle: "(Pollen, médicamenteuse, ...)",
    anamneseAllergiesQuestion: "Avez-vous des allergies connues ?",
    anamneseAllergiesQuestion_relative_male:
      "Votre proche a-t-il des allergies connues ?",
    anamneseAllergiesQuestion_relative_female:
      "Votre proche a-t-elle des allergies connues ?",
    anamneseAllergiesQuestionDetails: "Quelle(s) allergie(s) ?",
    anamneseDrugsQuestion: "Prenez-vous régulièrement des médicaments ?",
    anamneseDrugsQuestion_relative_male:
      "Votre proche prend-il régulièrement des médicaments ?",
    anamneseDrugsQuestion_relative_female:
      "Votre proche prend-elle régulièrement des médicaments ?",
    anamneseIllnessQuestionSubtitle: "(Diabète, hypertension, ...)",
    anamneseIllnessQuestion: "Avez-vous déjà eu des maladies chroniques ?",
    anamneseIllnessQuestion_relative_male:
      "Votre proche a-t-il déjà eu des maladies chroniques ?",
    anamneseIllnessQuestion_relative_female:
      "Votre proche a-t-elle déjà eu des maladies chroniques ?",
    anamneseIllnessQuestionDetails: "Quelle(s) maladie(s) ?",
    anamneseOperationsQuestion: "Avez-vous déjà été opéré(e) ?",
    anamneseOperationsQuestion_relative_male:
      "Votre proche a-t-il déjà été opéré ?",
    anamneseOperationsQuestion_relative_female:
      "Votre proche a-t-elle déjà été opérée ?",
    anamnesePregnantQuestion: "Êtes-vous enceinte ?",
    anamnesePregnantQuestion_relative_female:
      "Votre proche est-elle enceinte ?",
    antecedents: "Avez-vous des antécédents ophtalmologiques ?",
    apr: "Avril",
    askAgain: "Relancer la demande",
    ask_for_phone_re_enter:
      "Merci de rentrer de nouveau le téléphone du patient",
    ask_for_voiceCall: "Recevoir le code par appel téléphonique",
    aug: "Août",
    averageConsultationTime: "Durée moyenne des examens",
    backToHome: "Retour à l'accueil",
    birthdate: "Date de naissance",
    birthdatePlaceholder: "JJ / MM / AAAA",
    byPassEmail: "Je n'ai pas d'e-mail",
    byPassNir: "Je n'ai pas de carte vitale",
    enterRelativeNir:
      "Saisissez le numéro de carte vitale du bénéficiaire des droits",
    byPassNir_relative: "Le proche n’a pas de carte vitale",
    noBill: "Vous ne serez pas facturé.",
    doctorCancelCall:
      "Nous sommes navrés, le médecin a annulé la téléconsultation",
    callCancelled: "Annulée",
    callCannotBeCancelled: "Nous n'avons pas pu annuler la consultation",
    callExpired: "Expirée",
    callHasBeenCancelled: "La téléconsultation a été annulée",
    callMeVerify: "Recevoir un appel pour le code",
    call_ophtalmologist: "téléconsultation avec l'ophtalmologiste",
    call_ortho: "téléconsultation avec l'orthoptiste",
    call_page: "teleconsultation",
    cancel: "Annuler",
    cancelAndDisconnect: "Annuler et se déconnecter",
    cancelCall: "Annuler la consultation",
    cancelConsultation: "ANNULER MA DEMANDE",
    continueConsultation: "POURSUIVRE MA TÉLÉCONSULTATION",
    cancelTLC: "Vous allez annuler la demande de téléconsultation",
    cancer: "cancer",
    cannotLaunchTlc: "Impossible de lancer la consultation",
    cataract: "Cataracte",
    cguAgreement:
      "J'ai lu et accepte <b>les conditions générales d'utilisation</b>, ainsi que le traitement de mes données personnelles de santé pour l'utilisation du service",
    cguAndPrivacyTitle: "Conditions générales et données personnelles",
    change_phone: "Changer de numéro",
    childLessThanSix: "les enfants de moins de 6 ans",
    choseYourCountry: "Veuillez indiquer le pays",
    civility: "Civilité",
    clickMeToVanish: "puis cliquez-moi pour disparaitre !",
    comeBackToLogin: "Revenir à la connexion",
    comeBackToSurvey: "Revenir au parcours",
    confirm: "Confirmer",
    medicalcenter: "Centre de santé",
    chooseYourNationality: "Choisissez votre lieu de naissance",
    confirmHangupCall:
      "Êtes-vous sûr(e) de vouloir raccrocher la téléconsultation ?",
    confirmCancelCall: "Êtes-vous sûr(e) de vouloir annuler la demande ?",
    connectedAs: "Connecté en tant que ",
    consultationScheduledAt:
      "a confirmé la téléconsultation pour ce créneau. Il peut avoir du retard, merci de patienter.",
    contact_secretary:
      "Veuillez contacter le secrétériat \r\n afin de créer votre compte",
    continue: "Continuer",
    IUnderstandAndContinue: "Je continue quand même",
    createCall: "Lancer la consultation",
    creating_call: "Demande en cours",
    credit: "© 2021 MEDADOM Tous droits réservés",
    creditCard: "carte bancaire",
    waintingForCardInsertion: "En attente de l'insertion de la carte",
    dateInvalid: "La date de naissance est invalide",
    dec: "Décembre",
    defaultErrorForm: "Une erreur s'est glissée dans le formulaire",
    defaultReplyWhichWho: "L'un de nos médecins disponible",
    deleteCard: "Supprimer la carte",
    demo: "la démonstration des machines",
    demo_page: "demonstration-outils",
    diabetes: "diabète",
    digestiveDisease: "maladie digestive",
    doYouHaveAnAccount: "Avez-vous un compte MEDADOM ?",
    anamneseChoiceOfDoctor: "Que souhaitez-vous faire ?",
    anamneseConsultNow: "Consulter <b>dès maintenant</b>",
    consultNow: "Consulter dès maintenant",
    now: "maintenant",
    availability: "Disponibilité",
    thisDoctorIsNotAvailable_html:
      "Ce médecin est indisponible, mais un médecin du <b>CDS Mediksanté (Paris)</b> peut vous <b>prendre en charge immédiatement</b>",
    IConsultThisDoctor: "Je veux consulter ce médecin",
    ifDoctorDoNotExist:
      "Si le médecin que vous cherchez ne consulte pas encore sur MEDADOM, vous pourrez consulter un autre médecin disponible",
    requestDoctorPlaceholder: "Entrez le nom du médecin",
    anamneseChooseADoctor: "J'ai RDV avec un <b>médecin spécifique</b>",
    chooseADoctor: "J'ai rendez-vous avec un médecin spécifique",
    anamneseChooseADoctor_relative_female:
      "Ma proche a RDV avec un <b>médecin spécifique</b>",
    anamneseChooseADoctor_relative_male:
      "Mon proche a RDV avec un <b>médecin spécifique</b>",
    anamneseDoctorAvailableQuestion: "Quelle est votre situation ?",
    nirIsExpiredTitle: "Votre Carte Vitale n'est pas à jour !",
    nirIsExpiredContent: `<span>
      Cela peut avoir une incidence sur le remboursement de votre téléconsultation,
    </span>
        <br />
    <b>
      mettez là à jour auprès de votre pharmacien
    </b>`,
    anamneseDoctorAvailableQuestion_relative_male:
      "Quelle est la situation de votre proche ?",
    anamneseDoctorAvailableQuestion_relative_female:
      "Quelle est la situation de votre proche ?",
    doNotHaveDoctor: "<b>Je n'ai pas</b> de médecin traitant",
    doctorNotAvailable: "Mon médecin traitant <b>n'est pas disponible</b>",
    doctorNotAvailable_relative_female:
      "Le médecin traitant de ma proche <b>n'est pas disponible</b>",
    doctorNotAvailable_relative_male:
      "Le médecin traitant de mon proche <b>n'est pas disponible</b>",
    doctorNotInList: "Mon médecin n'est pas dans la liste",
    doctorAwaiting: "Votre médecin est prêt ! Votre consultation va commencer.",
    waitInFrontOfTheScreen: "Veuillez patienter devant l'écran...",
    documentAvailableInfo:
      "Retrouvez vos documents médicaux dans votre espace Medadom",
    inTab: ", dans l'onglet ",
    deviceAreON: "L'otoscope (A) et/ou le dermatoscope (D) sont allumés.",
    documents: "Documents",
    drugAllergy: "allergie aux médicaments",
    foodAllergy: "allergie alimentaire",
    email: "e-mail",
    Email: "E-mail",

    notSuitableTLC: "Téléconsultation non adaptée",
    IHaveRelativeConsent: "J'ai le consentement",
    enterRelativeInformations: "Ajout d'un proche",
    enter_lastname: "Veuillez saisir votre nom de famille",
    InputYourOldPassword: "Veuillez entrer votre ancien mot de passe",
    InputYourPassword: "Confirmez votre mot de passe",
    enter_password:
      "Définissez un mot de passe, \n\r pour la création de votre compte Medadom",
    enter_phone: "Rentrez votre numéro de téléphone",
    notSuitableTLCContent:
      "Les enfants de <b>moins de 3 ans</b> nécessitent un examen clinique.<br/>La téléconsultation n'est pas adaptée à cette prise en charge et  n'est donc <b>pas possible</b> dans ce cas.",
    IGotIt: "J'ai compris",

    errorCallState:
      "Une erreur est survenue lors du call, \n\r Veuillez contacter notre support",
    errorLogin: "Une erreur s'est glissée dans le formulaire",
    errorOccuredWhenSendingCode:
      "Une erreur est survenue lors de l'envoi du code",
    existingOrderError: "La téléconsultation existe déjà",
    expiredSession: "La session est expirée. Nous allons vous déconnecter.",
    feb: "Février",
    female: "Femme",
    finalePageTitle: "Merci de votre visite !",
    firstConsultation: "1ère consultation lunettes",
    firstname: "Prénom utilisé au quotidien",
    first_birth_firstname: "1er Prénom de naissance",
    first_firstname: "Prénom",
    first: "1er",
    of_birth: " de naissance",

    forMe: "Pour moi",
    forRelative: "Pour un proche",
    forgottenPassword: "J'ai oublié mon mot de passe",
    gender: "Genre",
    glassesRenewal: "Renouvellement des lunettes",
    glaucoma: "Glaucome",
    errorDate_shouldBeOlderThan: (minAge?: number) =>
      `Vous devez avoir plus de ${minAge || 3} ans.`,
    errorDate_shouldBeYoungerThan: (maxAge?: number) =>
      `La téléconsultation n'est pas possible après ${maxAge || 110} ans`,
    DD: "JJ",
    MM: "MM",
    YYYY: "AAAA",
    goBackToProfile: "Retour au profil",
    helper: "L'équipe MEDADOM vous informe :",
    doctorIsWritingYourDocs:
      "Le médecin est en train de rédiger vos documents médicaux...",
    stepCanTake: "Cette étape prend généralement",
    timeWaitingHungup: "5 à 10 minutes.",
    findDocumentsInYourProfile:
      "Retrouvez à tout moment vos documents médicaux depuis votre espace MEDADOM.",
    followingInstructions_html: (deviceName?: string) =>
      `Placer<br/><b>${deviceName ?? "l'objet"
      }</b><br/>selon les instructions du médecin`,
    putDown: (deviceName?: string) =>
      `Reposer <br/> <b>${deviceName ?? "l'objet"}</b>`,
    helperRegardingGenderRelative:
      "Vérifiez bien le genre de la personne que vous ajoutez !",
    hangup: "Raccrocher",
    hangupTitleWarning: "Souhaitez-vous raccrocher ?",
    hangup_subtitle: "Veuillez patienter quelques instants...",
    hangup_title: "`Rédaction des documents par le médecin`",
    heartDisease: "maladie cardiaque",
    hypertension: "hypertension",
    IRefuseConsent: "Annuler",
    IAcceptConsent: "J'accepte",
    consentTitleModal: "CGU",
    consentContentModal: `J'ai lu et accepte <a>les conditions générales d'utilisation</a>, ainsi que le traitement de mes données personnelles de santé pour l'utilisation du service`,
    inProgress: "En cours...",
    inactivity: "Vous allez être déconnecté pour inactivité",
    incorrectInput: "Merci d'entrer un champs valide pour",
    infectiousDisease: "maladie infectieuse",
    inform_anamnesis:
      "Le médecin a besoin de certaines informations médicales vous concernant",
    initial_step: "Parcours de votre téléconsultation",
    inform_anamnesis_relative_male:
      "Le médecin a besoin de certaines informations médicales concernant le proche",
    inform_anamnesis_relative_female:
      "Le médecin a besoin de certaines informations médicales concernant la proche",
    inputYour: "Entrez votre ",
    enterThe: "Entrez le ",
    inputYourBirthCountry: "Entrez votre pays de naissance",
    inputYourBirthCity: "Entrez votre lieu de naissance",
    inputYourRelativeBirthCity: "Entrez le lieu de naissance du proche",
    inputYourRelativeBirthCountry: "Entrez le pays de naissance du proche",
    unknownBirthPlace: "Passez à l'étape suivante",
    insertYourNir: "Insérez votre carte vitale",
    createPassword: "Veuillez créer un mot de passe",
    ineligibleReasonInformation:
      "Pour ces motifs, vous devez vous rendre chez votre médecin traitant ou consulter un médecin en présentiel.",
    invalidValidationCode:
      "Une erreur est survenue lors de la validation du code",
    invalid_password: "Mot de passe invalide",
    jan: "Janvier",
    joinCallIn: "Rejoignez l'appel dans",
    joinTheConsultation: "Rejoindre la consultation",
    jul: "Juillet",
    jun: "Juin",
    keratoconus: "Kératocône",
    kidneyDisease: "maladie rénale",
    lastname: "Nom utilisé au quotidien",
    birthLastname: "Nom de naissance",
    launch_tlc: "Lancer une téléconsultation",
    legalMentionArticleSix:
      "Conformément à l'article 6 du code de la santé publique, vous êtes libre du choix de votre opticien",
    lensesRenewal: "Renouvellement des lentilles",
    lessThan: "Moins de",
    lessThanAYear: "Moins d'1 an",
    locationError: "Veuillez renseigner un lieu valide",
    locationInvalid: "Veuillez selectionner un lieu de naissance",
    locationAddress: "Lieu de naissance",
    locationAddressError: "lieu de naissance invalide",
    login: "Se connecter",
    loginNir: "Valider",
    loginTitle: "J'ai déjà un compte patient",
    logoutTitle: "Souhaitez-vous être déconnecté ?",
    logout: "Se déconnecter",
    logoutAdmin: "Déconnexion pharmacie",
    male: "Homme",
    mar: "Mars",
    may: "Mai",
    me: "moi",
    medikSante: "MedikSanté",
    mismatchingErrorForm: "Les identifiants ne correspondent pas",
    mustContainLessThanHundredCharacters: "Le champ doit contenir moins de 100 caractères",
    cantContainSpaceAsFirstCharacters: "Le premier caractère ne peut être un espace ou tiret",
    cantContainTwoConsecutiveSpace: "Le champ ne peut contenir deux espaces consécutifs",
    cantContainTwoConsecutiveApostrophe: "Le champ ne peut contenir deux apostrophes consécutifs",
    cantContainTwoConsecutiveHyphen: "Le champ ne peut contenir que deux tirets consécutifs",
    cantContainHyphenFollowedByApostrophe: "Le champ ne peut contenir un tiret suivi d'un apostrophe ",
    cantContainHyphenFollowedBySpace: "Le champ ne peut contenir un tiret suivi d'un espace",
    cantFinishWithHyphenOrApostrophe: "Le champ ne peut pas finir par un tiret ou un apostrophe",
    shouldStartWithAlphaOrApostrophe: "Le champ doit commencer par un caractère ou un apostrophe",
    shouldContainAtLeastOneAlphaCharacter: "Le champ doit contenir au moins un caractère alphanumérique",
    shouldNotContainSlash: "Le champ ne peut contenir de slash",
    modify: "Modifier",
    ofTheRelative: "du proche",
    modifyRelative: "Modifier mon proche",
    of: "de",
    label_input_the_male: "Entrez le ",
    enter1erFirstname: "Entrez le 1er Prénom de naissance",

    inputThe: (name: string) => {
      if (["birthdate"].includes(name)) return "Entrez la " + fr[name]
      else return "Entrez le " + fr[name]
    },
    modifyThe: (name: string) => {
      return "Modifiez le " + fr[name]
    },
    modifyYour: (name: string) => {
      return "Modifiez votre " + fr[name]
    },

    addA: (name: string) => {
      return "Ajouter un " + fr[name]
    },

    nirWellKnown: "Le numéro de carte vitale est bien enregistré",
    exemptionEnded:
      "Fin de la mesure gouvernementale Covid-19 sur le tiers payant intégral. À compter du 1er octobre 2022, le patient devra à nouveau s’acquitter de la part complémentaire (remboursée par la mutuelle)",
    tlcExemptedDate: "Au 1er octobre 2022",
    tlcExemptedContent:
      "la prise en charge règlementaire de la Sécurité Sociale redeviendra de 70%, et le reste à charge de 30% (remboursable par votre mutuelle). Le contexte sanitaire actuel exonère d'avance de frais en téléconsultation jusqu'au 30/09/2022.",
    nextMeansYouAcceptCGU:
      "En poursuivant la demande, je confirme avoir pris connaissance des CGU",
    putHeadPhones: "Si la borne en est équipée, mettez le casque audio",
    moreThanAYear: "Plus d'1 an",
    needHelp: "Besoin d'aide ?",
    next: "Suivant",
    conflictError: "Déjà existant",
    nexmoError:
      "Ce numéro de téléphone ne peut être validé. Merci d'essayer avec un autre numéro",
    nir: "Numéro de carte vitale",
    nirInvalid: "Le numéro de carte vitale n'est pas valide",
    nirLengthError: "Le numéro de carte vitale doit contenir 15 chiffres",
    rppsLengthError: "Le RPPS/Adeli doit contenir un maximum de 11 chiffres",
    nirManualInput: "Passer l'étape",
    nirReaderError: "Nous n'avons pas pu récupérer les informations",
    codeSpacer: " ",
    codeAllowedChar: "9",
    nirMaskChar: "_",
    nirAllowedChar: "*",
    no: "NON",
    doctorIsPrescribing: "Rédaction des documents par le médecin",
    youWillBeInformedBySMS: "En cas d'avance, vous serez prévenu(e) par sms",
    youWillBeInformedBySMSFifo:
      "Vous serez prévenu(e) par SMS avant le début de votre téléconsultation",
    noAppointment: "Je n'ai pas de rendez-vous",
    noAppointment_relative_male: "Mon proche n'a pas de rendez-vous",
    noAppointment_relative_female: "Mon proche n'a pas de rendez-vous",
    noDocument: "le docteur n'a délivré aucune ordonnance",
    noEmailContentModal: `<div>
      <div class="center" style="margin-bottom:80px">
        <img  src="${cdn("icons/mail_picto.svg")}" alt="">
      </div>
      <table class="table-modal">
        <tr>
          <td></td>
          <td><b>En renseignant votre adresse email :</b></td></tr>
        <tr>
          <td><img height="100" src="${cdn(
      "icons/checked_circle-green.svg"
    )}" alt=""></td>
          <td>Vous pourrez recevoir des informations sur les documents médicaux liés à votre consultation</td>
        </tr>
        <tr>
          <td><img height="100" src="${cdn(
      "icons/checked_circle-green.svg"
    )}" alt=""></td>
          <td>Nos équipes médicales et administratives pourront vous contacter en cas de besoin</td>
        </tr>
      </table>
    </div>`,
    noEmailTitleModal: "Adresse e-mail obligatoire",
    noRelativesYet: "Il n'y a actuellement pas de proche",
    noNirTitleModal: "Attention",
    bornThe: "Né(e) le ",
    noNirContentModal:
      "Vous devrez régler la totalité de la téléconsultation médicale.",
    noHistory: "Pas d'historique",
    notAdapted: "La téléconsultation n'est pas adaptée pour :",
    notBornInFrance: "Je ne suis pas né(e) en France ?",
    bornInFrance: "Je suis né(e) en France ?",
    relativeBornInFrance: "Le proche est né(e) en France ?",
    relativeNotBornInFrance: "Le proche n'est pas né(e) en France ?",
    french: "France",
    foreign: "Étranger",
    unknown: "Inconnu",
    nov: "Novembre",
    office: "Cabinet",
    oct: "Octobre",
    olderThanFifty: "Avez-vous plus de 50 ans ?",
    optional: "(optionnel)",
    orderForWhichDoctor: "Avec quel médecin avez-vous rendez-vous ?",
    orderForWhichDoctor_relative_male:
      "Avec quel médecin votre proche a-t-il rendez-vous ?",
    orderForWhichDoctor_relative_female:
      "Avec quel médecin votre proche a-t-elle rendez-vous ?",
    orderForWhichHour: "À Quelle heure avez-vous rendez-vous ?",
    orderForWhichHour_relative_male:
      "À Quelle heure votre proche a-t-il rendez-vous ?",
    orderForWhichHour_relative_female:
      "À Quelle heure votre proche a-t-elle rendez-vous ?",
    orderForWho: "Pour quelle(s) personne(s) est la consultation ?",
    otherAllergy: "autre",
    otherDisease: "autre",
    otherOphtalmologicDisease: "Autre",
    page_admin: "administrateur",
    page_check_lastname: "validation-du-nom",
    page_check_password: "validation-du-mot-de-passe",
    page_check_phone: "validation-du-telephone",
    page_home: "maison",
    page_insert_relative: "creation-de-proche",
    page_lobby: "choisir-sa-connexion",
    page_login: "connexion",
    page_nir: "carte-vitale",
    page_not_authorized: "401",
    page_not_found: "404",
    page_order: "questions",
    page_register: "inscription",
    page_register_personal_data: "saisie-des-donnees-personnelles",
    page_reset_password: "reinitialisation-mot-de-passe",
    page_verify: "verification",
    page_verify_phone: "verification-du-mobile",
    page_welcome: "bienvenue",
    panel_for_secretary:
      "Si vous êtes la secrétaire, veuillez saisir le mot de passe administrateur",
    password: "Mot de passe",
    passwordMustBeEightLength:
      "Le mot de passe doit contenir au moins 8 caractères",
    password_validation_char: "8 caractères",
    password_validation_lower: "une lettre minuscule",
    password_validation_number: "un chiffre",
    password_validation_title: "Votre mot de passe doit contenir au moins:",
    password_validation_upper: "une lettre majuscule",
    patientPutError: "Nous n'avons pas pu mettre à jour votre profil",
    patientPutNirError: "Votre numéro de sécurité sociale n'est pas valide",
    patientPutNirSuccess: "Votre numéro de sécurité sociale est valide",
    payloadErrorForm: "Une erreur s'est glissée dans la requête",
    peoplesOlderThanSixtyFive: "les personnes de plus de 65 ans",
    phone: "Téléphone portable",
    phoneExistingErrorForm:
      "Le numéro de téléphone est déjà utilisé pour un autre compte",
    phoneInvalid: "Le téléphone n'est pas valide",
    phoneNotFound: "Le numéro de téléphone n'existe pas dans notre base",
    playerStopped: "Le Lecteur s'est arrêté.",
    pollenAllergy: "allergie au pollen",
    postCallTitle: "`Téléconsultation terminée avec succès`",
    post_call_page: "transition",
    previous: "Retour",
    print: "Imprimer",
    rePrint: "Ré-imprimer",
    rePrintTitle: "Ré-impression",
    rePrintContent:
      "Vous avez déjà envoyé une demande d'impression. Êtes-vous sûr(e) de vouloir ré-imprimer le document ?",
    beSureDocumentsArePrinted:
      "Soyez certain que vos documents ont bien été imprimés ou partagés avec le pharmacien, avant la déconnexion",
    printDocument: "Imprimer les documents",
    profile_myHistory: "Documents",
    profile_myInformations: "Informations",
    profile_myPayments: "Moyens de paiement",
    profile_mySettings: "Paramètres",
    provideYourMail:
      "merci de saisir votre email pour le suivi de votre téléconsultation",
    questionForWho: "Pour qui est la consultation ?",
    whoIs: "Qui est ",
    for: "pour ",
    you: "vous",
    questionWhichRelative: "Pour quel proche ?",
    questionWhichTime: "A quelle heure avez-vous rendez-vous ?",
    questionWhichWho: "Avec quel médecin avez-vous rendez-vous ?",
    question_step: "les questions pré-consultation",
    reset: "Vider le champ",
    re_enter_phone: "Re-saissir mon numéro de téléphone",
    readerInitialize: "Initialisation...",
    readerSocketGenericError:
      "Une erreur est survenue sur le lecteur de carte, \r\n Veuillez le saisir manuellement",
    readerSocketReadError:
      "Une erreur est survenue lors de la lecture de votre carte",
    readerStopped: "Le lecteur s'est arrêté",
    reading: "\tLecture en cours...",
    reason: "Quel est le motif de votre visite ?",
    reconnectAfterQuestion:
      "Vous allez être reconnecté à votre consultation suite à la saisie des questions pré-consultation",
    redirectAfterQuestion:
      "Vous allez revenir à la dernière étape (RDV ophtalmologiste) suite à la saisie des questions pré-consultation",
    register: "S'inscrire",
    registerStep_complementary: "Identifiants",
    registerStep_creation: "Création du compte",
    registerStep_nir: "Carte vitale",
    registerStep_personal: "Informations",
    registerStep_verification: "Téléphone",
    registerTitle: "Je m'inscris",
    register_creation_inProgress: "Création du compte en cours",
    register_validation_inProgress: "Validation du compte en cours...",
    relative: "proche",
    relativesAddedError: "Vos proches n'ont pas pu être ajouté à votre compte",
    relativesAddedSuccess: "Vos proches ont bien été ajoutés",
    relativeUseAnother: (name) => `Le proche utilise un autre ${name}`,
    containsAtLeast: "Contient au moins :",
    tenCharacters: "10 caractères",
    oneLowerCaseLetter: "Une lettre minuscule",
    oneUpperCaseLetter: "Une lettre majuscule",
    oneNumber: "Un Chiffre",
    IhaveReadAndAccept: "J'ai lu et j'accepte ",
    theCGU: "les conditions générales d'utilisation (CGU) ",
    andThe: "et la ",
    cannotLoadTLC: "Impossible de charger la tlc",
    cannotStartTLC: "Impossible de lancer la TLC",
    privacyPolicies: "politique de gestions des données personnelles ",
    ofMedadom: "de Medadom",
    weHaveSendAnSms: "Nous vous avons envoyé un SMS !",
    cannotSendTheSms: "Impossible de renvoyer le SMS",
    cannotCancel: "Impossible d'annuler",
    cannotStartReader: "impossible de lancer le lecteur",
    relativeUseAnotherFirstname: `Le proche utilise un autre Prénom`,
    relativeUseAnotherLastname: `Le proche utilise un autre Nom`,
    cannotRetrievedProfessionalIdentity: "impossible de récupérer l'identité du professionnel de santé",
    cannotRetrieveCompanions: "Impossible de récupérer les accompagnants",
    companion: "ACCOMPAGNANTS",
    cannotCreateCompanion: "Impossible de créer l'accompagnant : ",
    cannotDeleteCompanion :"Impossible de supprimer l'accompagnant",
    cannotGetCompanionsRelationship: "Impossible de récupérer les relations des accompagnants",
    updateVerifyMessage: (type:"email"|"sms") => {
      if(type==="email"){
        return "Un email a été envoyé"
      }
      if(type==="sms"){
        return "Un sms a été envoyé"
      }
    },
    price: "Tarif",
    MoreDetails: " En savoir plus...",
    CouldntSendTheCode: "le code n'a pas pu être renvoyé.",
    profilUpdated: "Nous avons bien modifié votre profil",
    passwordIdentical: "Les deux mots de passe sont identiques",
    impossibleToModify: "Impossible de modifier votre",
    RelativeHasBeenUpdated: "Nous avons bien modifié les informations de votre proche",
    RelativeModificationImpossible: "Impossible de modifier le proche",
    SomethingWentWrong: "Une erreur est survenue",
    WrongVerificationCode: "Le code de vérification est incorrect",
    relaunchPlayer: "Relancer le lecteur",
    removeYourNir: "Veuillez retirer votre carte",
    resendCodeInfo: "En cliquant sur le bouton, un nouvel SMS vous sera envoyé",
    sendItAgain: 'Renvoyer le code',
    SignupSuccess: "Vous êtes bien inscrit !",
    RefraichToUpdateInfo: "Veuillez rafraichir pour mettre à jour les informations",
    WrongCode: "Le code semble erroné",
    resend_code: "Renvoyer le code par sms",
    resetPasswordRequestIsMadeTitle:
      "Si un compte est associé à ce numéro de téléphone, un email vous a été envoyé",
    resetPasswordTitle:
      "Renseignez votre numéro de téléphone portable pour la réinitialisation de votre mot de passe",
    respiratoryDisease: "maladie respiratoire",
    referrerIsAHealthProfessional:
      "Si vous n'êtes pas en pharmacie, les documents seront envoyés au référent du dispositif",
    scheduledConsultation: "Téléconsultation programmée",
    subscribePlaceholder:
      "Autoriser MEDADOM à vous envoyer des communications par e-mail",
    doctorBusy: "Tous nos docteurs, sont occupés. Nous sommes navrés.",
    selectDayBirthdate: "Jour",
    selectMonthBirthdate: "Mois",
    selectYearBirthdate: "Année",
    sendCode: "Envoyer le code",
    sendDocumentsToTheReferrer: "Envoyer au pharmacien",
    sent: "Envoyé !",
    sep: "Septembre",
    smsNotReceived: "Je n'ai pas reçu le sms",
    step_page: "etape",
    strabismus: "Strabisme",
    supportNumber: "support 01 86 65 55 15",
    teleconsultationTraining: "Formation à la téléconsultation",
    tlcMade: "Téléconsultations réalisées",
    thanks: "Merci",
    turnThemOff: "Merci de les éteindre avant de continuer.",
    tlcOf: "Téléconsultation du",
    thanksForYourAnswers:
      "Merci d’avoir répondu aux questions. Les données renseignées restent confidentielles.",
    thirtyMinutes: "30 min",
    tooltipResetPassword:
      "Un SMS vous sera envoyé avec les informations pour vous connecter",
    tooManyCallsForACustomer:
      "Vous avez déjà effectué une téléconsultation aujourd'hui",
    touch_screen: "Touchez l'écran pour démarrer",
    tryAgain: "Relancer le lecteur",
    useCard: "Lancer la téléconsultation",
    useAnother: (name) => `J'utilise au quotidien un autre ${name}`,
    preferedLastname: "Nom utilisé",
    preferedFirstname: "Prénom utilisé",
    IuseAnotherFirstname: "J'utilise au quotidien un autre Prénom",
    IuseAnotherLastname: "J'utilise au quotidien un autre Nom",
    male1stBirthFirstnamePlaceholder: "Jean...",
    male1stFirstnamePlaceholder: "Pierre...",
    BirthLastnamePlaceholder: "Dupont...",
    LastnamePlaceholder: "Durant...",
    female1stBirthFirstnamePlaceholder: "Marie...",
    female1stFirstnamePlaceholder: "Nathalie...",
    unknownBirthLocation: "Lieu de naissance inconnu",
    validate: "Valider",
    verifiedPhoneError: "Le code de vérification est erroné",
    verifiedPhoneSuccess: "Votre téléphone a bien été validé",
    companionIdentification: "Identification accompagnant",
    verifyCallSuccess: "Un SMS vous a été renvoyé.",
    verifyCallSendSuccess:
      "Vous allez recevoir un appel dans quelques secondes",
    verifyNir: "Vérifiez les numéros avant de passer à l'étape suivante",
    waitingForNirReader: "Recherche du lecteur de carte vitale...",
    answerIn: "La recherche d’une disponibilité peut prendre encore jusqu’à ….",
    tlcExpectedIn: "Téléconsultation prévue dans : ",
    weAreLookingForDoctors:
      "Nous recherchons actuellement un médecin disponible, merci de patienter.",
    weekly: "Cette semaine",
    monthly: "Ce mois-ci",
    yearly: "Cette année",
    total: "Total",
    daily: "Aujourd'hui",
    in: "En",
    welcome: "Bienvenue",
    wichOnes: "Lesquels (sélection multiple possible) ?",
    writeNir: "Inscrivez vos numéros",
    writeVerifyCode: "Entrez le code reçu au ",
    wrongNirLength: "Veuillez saisir 15 chiffres",
    wrongWay:
      "Il semble que vous ne soyez pas arriver ici en suivant le bon chemin",
    yes: "OUI",
    youHaveCancelledCall: "Vous avez annulé la téléconsultation",
    youNeedToRemoveLensesBeforeConsultation:
      "L’examen médical nécessite un retrait de vos lentilles la veille de la téléconsultation.",
    youWillLaunchATLC: "Vous allez lancer une demande de téléconsultation",
    yourDocuments: "Vos Documents",
    yourPharmaAccount: "Votre compte pharmacie",
    yourWaitingTime: "Votre temps d'attente estimé :",
    // tutorial
    take: "Prenez",
    itIsDone: "C'est fait !",
    finish: "Terminer",
    iTurnOff: "J'ai éteint",
    turnOffDevice_html: (
      deviceName: string
    ) => `<b>Glisser le curseur pour éteindre ${deviceName}</b>
    <br />
    (position OFF)`,
    turnOnDevice_dermatoscope_html: `Glisser <b>le curseur jusqu'au bout </b> pour allumer
    <br />
    (position LED)`,
    turnOnDevice_otoscope_html: `Glisser <b>le curseur jusqu'au bout </b> pour allumer
    <br />
    (position LED2)`,
    seemsDeviceNotOn: (
      deviceName: string
    ) => `Il semblerait que ${deviceName} ne fonctionne pas
    ou ne soit pas branché`,
    lightDevice_html: `Glisser <b>la molette de luminosité au maximum</b>`,
    focusDevice_html: `<b>Ajuster la netteté de l'image</b> si besoin
    <b> en déplaçant la molette grise</b>`,
    isNotTurnOn: "n'est pas branché",
    inUsing: "Utilisation en cours",
    useVideoDevice_html: (deviceNaming: string) => {
      if (deviceNaming === "otoscope")
        return `Placer <b>votre pouce sur la molette grise</b> puis <b>introduire l'embout</b> dans l'oreille`
      if (deviceNaming === "dermatoscope")
        return "<b>Placer l'embout sur la zone à montrer</b>"
    },
    warning: "Attention",
    RelativeAddedSuccessfully: "Bravo, vous avez ajouté un proche",
    modalContent_changePatientInformation_html:
      "Si vous souhaitez téléconsulter pour <b>une autre personne</b>, vous<br/> devez <b>ajouter un proche</b> lors de votre prochain parcours de<br/>téléconsultation <b>et non modifier les données</b>",
    modifyDatas: "Modifier les données",
    datas_and_consent_title: "Données et consentement",
    datas_and_consent_point_1:
      "Je confirme mon libre choix à la téléconsultation et qu'elle ne m'est imposée par aucun contexte particulier",
    datas_and_consent_point_2:
      "J'accepte la collecte de mes données personnelles nécessaires :",
    datas_and_consent_point_2_checkbox_1:
      "à mon parcours de soins (gestion du dossier administratif, médical, facturation, sms)",
    datas_and_consent_point_2_checkbox_2:
      "à l'établissement de statistiques anonymes et à l'amélioration du produit dans le respect de la réglementation applicable",
    datas_and_consent_point_3:
      "Medadom et ses sous-traitants protègent vos données de santé. Pour plus d'informations ainsi que les éventuels liens d'intérêts, voir",
    datas_and_content_point_3_ref: " la politique de confidentialité",
    IConfirmAndContinue: "Je confirme et continue",
    verifyEmail:
      "Pour confirmer le changement, entrez le code reçu par email à l'adresse suivante",
    add: "Ajouter",
    IConfirmmyConsentDataChoice:
      "Je confirme mon libre choix à la téléconsultation et accepte la collecte de mes données personnelles.",
    IConfirmMyConsentDataChoiceExempte: "Je confirme avoir pris connaissance des CGU et mon libre choix à la téléconsultation, et j'accepte la collecte de mes données personnelles",
    verifyPhone:
      "Pour confirmer le changement, entrez le code reçu au numéro suivant",
    addCarer: "Ajouter un accompagnant",
    addingCarer: "Ajout d'un accompagnant",
    addProfessional: "Ajouter un professionnel",
    proOrReferent: "Un professionnel de santé ou référent",
    aRelative: "Un proche",
    asCarerIAccept: "L'accompagnant s'engage à ",
    toKeepConfidential: "garder confidentielles les informations échangées ",
    duringTlc: "lors de la téléconsultation",
    validateAndContinue: "je valide et continue",
    insertAdminEmail:
      "Par mesure de sécurité, merci de demander à l'administrateur du dispositif de téléconsultation d'indiquer son email",
    IsaPro: "Est-ce un professionnel de santé ?",
    isCarerInTheList: "L'accompagnant est-il dans la liste ?",
    exemplePro: "(pharmacien, infirmier, ...)",
    whichRelativeIsCaringYou: "Quel proche vous accompagne ?",
    whichProfessionalIsCaringYou: "Quel professionnel vous accompagne ?",
    whichProfessionalIsCaringYou_relative:
      "Quel professionnel accompagne votre proche ?",
    whatIsRelationship: "Quel est le lien de parenté de l'accompagnant ?",
    confirmRemoveCarer: "Vous allez supprimer l'accompagnant suivant :",
    incorrectFieldAdminEmail: "Le champ est incorrect",
    forMoreSecurity: "Pour plus de sécurité",
    insertFirstnameCarer: "Entrez le prénom de l'accompagnant",
    insertLastnameCarer: "Entrez le nom de famille de l'accompagnant",
    insertPhoneCarer: "Entrez le numéro de téléphone de l'accompagnant",
    insertRpps: "Entrez le RPPS / Adeli du professionnel de santé accompagnant",
    addPro: `Souhaitez-vous ajouter ce professionnel ?`,
    healthPro: "Professionnel de santé",
    HealthProfessional: "Professionnel de santé",
    Referer: "Référent",
    delete: "Supprimer",
    otherCarer: "Autre accompagnant",
    noCarer: "Aucun accompagnant",
    ImCaredForThisTlc_relative:
      "Mon proche n'est pas autonome et est accompagné(e) pour cette consultation",
    ImCaredForThisTlc:
      "Je ne suis pas autonome et je suis accompagné(e) pour cette consultation",

    whoIsCaringYouForTheTLC:
      "Qui vous accompagne pour cette téléconsultation ?",
    whoIsCaringYouForTheTLC_relative:
      "Qui accompagne votre proche pour cette téléconsultation ?",
    whoIsCaringYou: "Quelle personne vous accompagne ? ",
    whoIsCaringYou_relative: "Quelle personne accompagne votre proche ?",
    required: "Valeur incorrecte",
    passwordHasNotBeenChangedSince_html: (days: number) => {
      if (days <= 0)
        return `<div>Votre mot de passe a <b>expiré</b>, cela fait plus de ${Math.abs(days)} ${Math.abs(days) <= 1 ? "jour" : "jours"} que vous ne l'avez pas modifié. Pour plus de <b>sécurité</b>, veuillez le modifier <b>dès à présent</b>.</div>`
      if (days === 1)
        return `<div>Votre mot de passe <b>expire demain.</b> Pour des raisons de <b>sécurité</b>, nous vous encourageons à le renouveler dès à présent.</div>`
      return `<div>Votre mot de passe <b>expire dans ${days} jours.</b> Pour des raisons de <b>sécurité</b>, nous vous encourageons à le renouveler dès à présent.</div>`
    },
    later: "Plus tard",
    modifyPassword: "Modifier mon mot de passe",
    uneligibleTitle: "Veuillez noter que la téléconsultation n'est pas adaptée pour les motifs suivants :",
    lessthan3yearOld:"Les enfants de moins de 3 ans",
    unaccompaniedMinors: "Les mineurs non accompagnés",
    ExtendedSickLeave: "Prolongation d'arrêt de travail",
    fitnessCertificate: "Certificat d'aptitude (sport, crèche)",
    PrescriptionOfPsychoactive: "Prescription de traitements psychoactifs de dépendance",
    IhaveUnderstood: "j'ai compris et je continue",
    photoOfBurn: "(photos de la brûlure, …)",
    photoOfJoins: "(photos des articulations, ...)",
    photoOfLesion: "(photos de la lésion, …)",
    photoOfThroat: "(photos de la gorge, …)",
    photoOfTheEye: "(photos de l'oeil, …)",
    photoOfSting: "(photos de la piqûre, ...)",
    PrescriptionToBeRenewed: "(ordonnance à renouveler, traitement, ...)",
    analysisResults: "(résultats d'analyse, …)",
    mister: "M.",
    miss: "Mme",
    sendBackTheCode: "RENVOYER LE CODE DANS"

};

export default fr;